import { createContainer } from 'unstated-next'
import client from '../../../lib/client'
import { PROFILE_KEY } from '~/lib/QueryKeys'
import { useQuery } from '@tanstack/react-query'

const fetchProfile = async () => {
  const {
    fullname,
    firstname,
    email,
    tenant,
    keys,
    timezone,
    availableTenants,
  } = await client.account.get()

  return {
    email,
    firstName: firstname,
    fullName: fullname,
    timezone,
    apiKeys: keys.map(
      ({ id, client_id: apiKey, client_secret: apiSecret, description }) => ({
        id,
        apiKey,
        apiSecret,
        description,
      }),
    ),
    organisation: tenant.name,
    switchableTenants: availableTenants
      .filter(availableTenant => availableTenant.id !== tenant.id)
      .sort((a, b) => a.name.localeCompare(b.name)),
  }
}

export function useProfile() {
  return useQuery({
    queryKey: [PROFILE_KEY],
    queryFn: async () => {
      return await fetchProfile()
    },
  })
}

const ProfileContainer = createContainer(useProfile)

export default ProfileContainer
