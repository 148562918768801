import { useEffect, useState } from 'react'
import {
  PAGINATION_DEFAULT_INITIAL_PAGE,
  PAGINATION_DEFAULT_LIMIT,
} from '~/lib/Constants'

const usePagination = ({
  initialPage = PAGINATION_DEFAULT_INITIAL_PAGE,
  limit = PAGINATION_DEFAULT_LIMIT,
  watch = [],
} = {}) => {
  const [page, setPage] = useState(initialPage)

  // make sure we always reset the counter,
  // this would cause bugs when using the same pagination hook on tabbed views
  useEffect(() => {
    setPage(0)
  }, watch)

  const skip = limit * page

  return {
    limit,
    skip,
    page,
    setPage,
  }
}

export default usePagination
