import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { Icon, colors, clearSky, carbon } from '@waylay/react-components'
import { TabsItem } from './Tabs'

export const Name = styled.span`
  font-weight: 500;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NameWithEllipsis = styled(Name)`
  white-space: nowrap;
  line-height: 1.5em;
`

export const Identifier = styled.span`
  font-size: 0.9em;
  font-weight: normal;
  text-decoration: none;
  color: ${carbon};
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const IdentifierWithEllipsis = styled(Identifier)`
  white-space: nowrap;
  width: auto;
`

export const Title = props => {
  const elem = props.to ? styled(Link) : styled.span
  const clickable = props.to || props.onClick

  const comp = elem`
    text-decoration: none;
    color: inherit;
    width: max-content;
    cursor: ${clickable ? 'pointer' : 'inherit'};
    display: contents;

    ${NameWithEllipsis} {
      margin-right: 0.5em;
      color: ${clickable ? clearSky : 'inherit'};
    }

    ${Name} {
      color: ${clickable ? clearSky : 'inherit'};
    }
  `

  const onClick = e => {
    e.stopPropagation()
  }

  return React.createElement(comp, { onClick, ...props })
}

export const Description = styled.div`
  font-size: 0.9rem;
`

export const Meta = styled.div`
  display: flex;

  & > * + * {
    margin-left: 0.5em;
  }
`

export const MetaItem = styled.span`
  font-size: 0.8em;
  color: ${carbon};
  text-decoration: none;
  vertical-align: middle;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;

  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dotted;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a:hover {
    color: ${colors.withWeight(carbon, 700)};
  }
`

export const MetaItemOverflow = styled.span`
  font-size: 0.8em;
  color: ${carbon};
  text-overflow: ellipsis;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  overflow: hidden;
  white-space: pre;
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};

  & i {
    margin-right: 0.2em;
  }
`

export const TabMenu = ({ items = [] }) => {
  const tabs = items.map(({ name, to, icon }) => (
    <TabItem key={to} to={to}>
      {icon && <Icon name={icon} />}
      {name}
    </TabItem>
  ))

  return <nav>{tabs}</nav>
}

const TabItem = ({ children, to }) => {
  const { pathname } = useLocation()

  const isActive = pathname.endsWith(to)

  return (
    <TabsItem active={isActive} as={NavLink} to={to}>
      {children}
    </TabsItem>
  )
}
