import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { cloneDeep, get, isEmpty } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { createContainer } from 'unstated-next'
import client from '~/lib/client'
import { IGlobalFilersVisibility, IGlobalFilter } from '~/lib/types'

const INITIAL_VISIBILITY = {
  resources: true,
  plugins: true,
  webscripts: true,
  tasks: true,
  template: true,
  resourceTypes: true,
  constraints: true,
}

const useGlobalFilters = () => {
  const { addToast } = useToasts()
  const [filtersVisibility, setFiltersVisibility] =
    useState<IGlobalFilersVisibility>(INITIAL_VISIBILITY)

  const queryClient = useQueryClient()

  const filters = useQuery({
    queryKey: ['user-filters'],
    queryFn: async () => await client.account.listFilters(),
    gcTime: 0,
  })

  const add = useMutation({
    mutationFn: async (args: {
      name: string
      filter: string
    }): Promise<any> => {
      const { name, filter } = args
      return await client.account.createFilter({
        name,
        filter,
      })
    },
    onSuccess: response => {
      const newFilters = cloneDeep(filters.data)
      newFilters.push(response)
      queryClient.setQueryData(['user-filters'], newFilters)
      addToast('Successfully added filter', { appearance: 'success' })
    },
    onError: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Error: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })

  const remove = useMutation({
    mutationFn: async (args: { id: string }): Promise<any> => {
      const { id } = args
      return await client.account.removeFilter(id)
    },
    onSuccess: () => {
      filters.refetch()
      addToast('Successfully removed filter', { appearance: 'success' })
    },
    onError: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Error: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })

  const edit = useMutation({
    mutationFn: async (args: {
      id: string
      filter?: string | undefined
      global?: boolean | undefined
    }): Promise<any> => {
      const { id, filter, global } = args
      const filterObject = {}
      if (filter) {
        filterObject['filter'] = filter
      }
      if (global) {
        filterObject['global'] = global
      }
      return await client.account.updateFilter(id, { ...filterObject })
    },
    onSuccess: response => {
      const newFilters = cloneDeep(filters.data)
      const index = newFilters.findIndex(filter => filter.id === response.id)
      if (response.global) {
        newFilters.forEach(filter => (filter.global = false))
      }
      newFilters[index] = response
      queryClient.setQueryData(['user-filters'], newFilters)

      addToast('Successfully updated', { appearance: 'success' })
    },
    onError: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Error: ${message}`, { appearance: 'error' })
    },
    throwOnError: false,
  })

  const globalFilter: IGlobalFilter = useMemo(() => {
    if (filters?.data) {
      const filter = filters.data.find(filter => filter.global)
      if (filter === undefined) {
        return null
      }
      const newFilter = cloneDeep(filter)
      //@ts-expect-error
      newFilter.wql = filter.filter
        .split(';')
        .map(t => `tag:"${t}"`)
        .join(' or ')

      return newFilter
    }
  }, [filters.data])

  const setFilterVisibility = (module: keyof IGlobalFilersVisibility) => {
    setFiltersVisibility(prev => ({
      ...prev,
      [module]: !filtersVisibility[module],
    }))
  }

  return {
    filtersVisibility,
    setFilterVisibility,
    filters,
    add,
    edit,
    reload: filters.refetch,
    globalFilter,
    remove,
  }
}

const GlobalFiltersContainer = createContainer(useGlobalFilters)
export default GlobalFiltersContainer
