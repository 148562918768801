export enum ConstraintTypes {
  numeric = 'numeric',
  string = 'string',
  boolean = 'boolean',
  object = 'object',
  array = 'array',
  enum = 'enum',
  resourceRef = 'resourceRef',
}

export interface IConstraint {
  id: string
  name: string
  tags?: string[]
  description: string
  attributes: ConstraintAttribute[] | []
}

export type ConstraintAttribute =
  | IStringAttribute
  | INumericAttribute
  | IBooleanAttribute
  | IEnumAttribute
  | IResourceRefAttribute
  | IObjectAttribute
  | IArrayAttribute

interface IAttributeBase {
  name: string
  required: string
}

export interface IStringAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.string
    minLength?: number
    maxLength?: number
  }
}

export interface INumericAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.string
    minimum?: number
    maximum?: number
  }
}

export interface IBooleanAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.boolean
  }
}

export interface IEnumAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.enum
    enumType: EConstraintType.string | EConstraintType.numeric
    items: string[] | number[]
  }
}

export interface IResourceRefAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.resourceRef
    attributes?: ConstraintAttribute[]
    exists?: Boolean
    resourceTypes?: string[]
  }
}

export interface IObjectAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.object
    attributes: ConstraintAttribute[]
  }
}

export interface IArrayAttribute extends IAttributeBase {
  type: {
    type: EConstraintType.array
    elementType: EConstraintType
    minLength?: number
    maxLength?: number
    uniqueValues?: boolean
    contains?: string[] | number[] | boolean[]
  }
}

export enum EConstraintType {
  string = 'string',
  numeric = 'numeric',
  boolean = 'boolean',
  resourceRef = 'resourceRef',
  array = 'array',
  enum = 'enum',
  object = 'object',
}

export type ElementType =
  | ConstraintTypes.numeric
  | ConstraintTypes.string
  | ConstraintTypes.boolean
  | ConstraintTypes.object

export type EnumType = ConstraintTypes.numeric | ConstraintTypes.string
