import {
  PropertyFormatType,
  PropertyType,
  TaskStatus,
  TaskType,
} from '~/lib/types'
export interface ITag {
  key: string
  value: string
}

export interface ITaskOptions
  extends IPeriodicTaskOptions,
    IScheduledTaskOptions {
  ID?: string
  name: string
  type: TaskType
  template?: string
  resource?: string
  start?: boolean
  tags?: ITag[]
  variables?: Record<string, string>
  parallel?: boolean
  resetObservations?: boolean
  gatesNeedFullObservation?: boolean
}

export interface ITemplateVariable extends IProperty {
  displayName: string
  description: string
}

export interface IProperty {
  name: string
  type: PropertyType
  mandatory?: boolean
  buttonText?: string
  defaultValue?: any
  dataType?: any
  format?: {
    type: PropertyFormatType
    values?: Object[]
    strict: boolean
  }
}

interface IPeriodicTaskOptions {
  frequency: number
}

interface IScheduledTaskOptions {
  cron?: string
  rrule?: string
  timeZone?: string
}
export type TaskVariable = Record<string, string>

export interface IPropertyInput {
  label?: string
  property: IProperty
  setShowDescriptor?: any
  value: any
  pluginValue?: string
  isDeclarativelyBound?: boolean
  setFieldValue: (name: string, value: any) => void // set formik value
  testId?: string
  setDescriptorName?: any
  setShouldShowDescriptor?: any
  values?: any
  isClearable?: boolean
  isSearchable?: boolean
  isError?: boolean
  testid?: string
  removeError?: (name: string) => void
}

export enum TabViewHeaders {
  Timeline = 0, // rest are auto-incremented
  Debugger,
  Logs,
  Problems,
}

export function isValidationError(
  error: ITemplateError | Error,
): error is IValidationError {
  return (error as ITemplateError).code === 'VALIDATION'
}

export interface ITemplateError extends Error {
  code: string
  error: string
  statusCode: number
  response?: {
    data: IValidationError
    config: {
      data: string
    }
  }
}

export interface IValidationError extends ITemplateError {
  code: 'VALIDATION'
  details: IValidationDetailError[]
}

export enum MinimapActions {
  Show = 'show',
  Hide = 'hide',
}

export enum ActionType {
  SetNode = 'UPDATE_NODE',
  RemoveNode = 'REMOVE_NODE',
  updateNodeConfig = 'UPDATE_NODE_CONFIG',
  removeNodeConfig = 'REMOVE_NODE_CONFIG',
}

export interface IInfoBox {
  x: number
  y: number
  text: string
}

export interface INode {
  name: string
}

export type NodeValidationError = IValidationDetailError

export interface ITaskTemplate {
  name: string
  user: string
  metadata?: {
    infoBoxes: IInfoBox[]
  }
  variables?: {
    [key: string]: string
  }
  createTime: number
  lastUpdateTime: number
  nodes: INode[]
  simplifiedGraph: any
  discoveryTemplate: boolean
}

export type FieldValidationError = Omit<
  Required<NodeValidationError>,
  'suggestion'
>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type GlobalValidationError = PartialBy<IValidationDetailError, 'details'>

export interface IValidationDetailError {
  type: string
  message: string
  severity: 'ERROR' | 'WARNING'
  details: {
    nodeId: string
    actuatorLabel: string
    plugin: Object
    property?: string
    binding?: string
    cycle?: string[]
  }
  suggestion?: string
}

interface ITaskHistory {
  [key: string]: Array<{
    state: string
    time: number
    probability: number
  }>
}
interface IActuatorInvocation {
  node: string
  action: string // name of the actuator
  time: number // epoch millis
  error?: string
}

export interface ITask {
  commands: string[]
  createTime: number
  cron?: string
  rrule?: string
  frequency?: number
  history: ITaskHistory
  ID: string
  template?: string
  lastExecutionTime: number
  name: string
  network: {
    nodes?: any[]
  }
  rawData: object
  resetObservation: boolean
  actuatorsLastInvocations?: IActuatorInvocation[]
  resourceIds: string[]
  startTime: number
  status: TaskStatus
  tags: object
  type: TaskType
  user: string
  variables?: object
  messages?: string[]
  finishedTime?: number
  health?: object
}
