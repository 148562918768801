import React, { useEffect, useRef, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Button,
  Icon,
  Popup,
  Segment,
  List,
  Label,
  StreamlineIcon,
  amethyst,
  carbon,
} from '@waylay/react-components'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet-async'
import { get, map, noop } from 'lodash-es'
import { decode, encode, AnnotationType } from '@waylay/bn-parser'
import GraphEditor from '@waylay/graph-editor'
import { Menu } from '@streamlinehq/streamline-bold/lib/interface-essential'
import NodeInformation from '../Tasks/NodeConfiguration'
import { NodeInfoWrapper, GraphContainer } from '../Tasks/Debugger'
import { AbsoluteTimestamp } from '../Common/Timestamp'
import plugsContainer from '../PluginsAndWebscripts/Plugins/usePlugs'
import ErrorMessage from '../Messages/Error'
import useTemplateFetch from './useTemplateFetch'
import { TemplateContainer } from './useTemplate'
import useTaskFormModal from '../Tasks/Detail/FormModal/useTaskFormModal'
import useRemoveModal from './Remove'
import { IfPending, IfRejected, IfFulfilled } from '../Common/QueryHelpers'
import Alerter from './Alerter'
import DangerListItem from '~/components/Common/DangerListItem'
import { WaylayEntity } from '~/lib/types'
import { RecentItem } from '~/components/Common/RecentItem'
import Tags from '~/components/Common/Tags'
import client from '~/lib/client'
import { useToasts } from 'react-toast-notifications'
import {
  ButtonContainer,
  DetailContainer,
  DetailsGrid,
  DetailName,
  DetailMeta,
  MetaContainer,
} from '~/components/Common/DetailsGrid'
import Loading, { ILoadingConfig } from '../Common/Loading/Loading'
import useWindowDimensions from '../Simulator/useWindowDimensions'
import { generateTemplatesDetailsLoaderConfig } from '../Common/Loading/Config/TemplatesConfig'
import { createTitle } from '~/lib/util'
import { useMutation } from '@tanstack/react-query'

export const annotationsMap = {
  [AnnotationType.dataTrigger]:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@v12.1.4/assets/72x72/26a1.png',
  [AnnotationType.tickTrigger]:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@v12.1.4/assets/72x72/23f1.png',
  [AnnotationType.loopDef]:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@12.1.4/assets/72x72/27b0.png',
  error:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@v12.1.4/assets/72x72/26a0.png',
}

const DetailView = () => {
  const { id } = useParams()
  const fetchTemplate = useTemplateFetch(id)
  const { width } = useWindowDimensions()

  const loaderConfig: ILoadingConfig[] =
    generateTemplatesDetailsLoaderConfig(width)

  return (
    <>
      <IfPending state={fetchTemplate}>
        {loaderConfig.map((config, index) => (
          <Loading {...config} positionInArray={index} key={index} />
        ))}
      </IfPending>
      <IfRejected state={fetchTemplate}>
        {error => <ErrorMessage error={error} />}
      </IfRejected>
      <IfFulfilled state={fetchTemplate}>
        {data => (
          <TemplateDetail
            data={data}
            reload={fetchTemplate.reload}
            promoteToDiscoveryTemplate={
              fetchTemplate.promoteToDiscoveryTemplate
            }
            demoteDiscoveryTemplate={fetchTemplate.demoteDiscoveryTemplate}
          />
        )}
      </IfFulfilled>
    </>
  )
}

export const TemplateDetail = ({
  data,
  reload,
  promoteToDiscoveryTemplate,
  demoteDiscoveryTemplate,
  showAlerts = true,
}) => {
  const { template, taskCount, resourceTypes } = data
  const { download } = TemplateContainer.useContainer()
  const graphCanvas = useRef()
  const graphInstance = useRef()
  const plugs = plugsContainer.useContainer()
  const [focusedNodes, setFocusedNodes] = useState([])
  const focusedNode = focusedNodes.length === 1 ? focusedNodes[0] : null

  const name = get(template, 'name')

  useEffect(() => {
    // we have to wait until the DOM elem is available and the plug cache is populated
    if (!graphCanvas.current || !template) return

    const network = template

    const graph = new GraphEditor({
      elements: [],
      container: graphCanvas.current,
      editable: false,
      selectable: true,
      controls: true,
      theme: 'light',
      parser: {
        encode,
        decode: (network, options) =>
          decode(network, { ...options, annotationsMap }),
      },
    })

    graph.on('nodeFocus', () =>
      setFocusedNodes(graph.getSelectedNodes().map(n => n.data)),
    )
    graph.on('nodeBlur', () =>
      setFocusedNodes(graph.getSelectedNodes().map(n => n.data)),
    )

    graph.import(network, { plugCache: plugs.data })
    graph.fitToContainer()

    graphInstance.current = graph
  }, [graphCanvas.current, JSON.stringify(template)])

  return (
    <>
      <Helmet title={createTitle(name, 'Templates')} />
      <RecentItem id={name} name={name} type={WaylayEntity.Template} />
      <Header
        template={template}
        taskCount={taskCount}
        resourceTypes={resourceTypes}
        handleDownload={async () => await download(template)}
        onPromote={() => promoteToDiscoveryTemplate.mutate()}
        onDemote={() => demoteDiscoveryTemplate.mutate()}
      />
      {showAlerts && (
        <Alerter
          nodes={get(template, 'nodes', [])}
          templateId={template.name}
          taskCount={taskCount}
          onMigration={() => reload()}
        />
      )}
      <GraphWrapper padding={0}>
        <GraphContainer ref={graphCanvas} />
        {focusedNode && (
          <NodeInfoWrapper>
            <NodeInformation node={focusedNode} />
          </NodeInfoWrapper>
        )}
      </GraphWrapper>
    </>
  )
}

const MarginTop = css`
  margin-top: 1rem;
`

const GraphWrapper = styled(Segment)`
  ${MarginTop};
  position: relative;
`

const Header = ({
  template,
  taskCount,
  resourceTypes,
  handleDownload,
  onPromote,
  onDemote,
}) => {
  const { name, user, lastUpdateTime, discoveryTemplate, description } =
    template
  const popupRef = useRef(null)
  const { addToast } = useToasts()

  const reload = useMutation({
    mutationFn: async () =>
      await client.tasks.batch.raw('reload', { template: template.name }),

    onError: () => {
      addToast('Failed to reload tasks', { appearance: 'error' })
    },
    onSuccess: () => {
      addToast('Successfully reloaded tasks', { appearance: 'success' })
    },
    throwOnError: false,
  })

  const { showModal } = useTaskFormModal({ template: name })
  const [showRemoveModal] = useRemoveModal({
    id: name,
  })

  const handlePromote = () => {
    onPromote()
    popupRef.current?.hide()
  }

  const handleDemote = () => {
    onDemote()
    popupRef.current?.hide()
  }

  return (
    <>
      <DetailsGrid>
        <DetailContainer>
          <DetailName value={name} isEditable={false} />
          <div style={{ fontSize: '0.9em', color: carbon }}>{description}</div>
          <MetaContainer>
            {discoveryTemplate && (
              <Label outline size="tiny" color={amethyst}>
                <Icon name="wifi_tethering" /> Discovery
              </Label>
            )}
            <DetailMeta text={user}>
              <Icon name="person_outline" />
            </DetailMeta>
            <AbsoluteTimestamp
              timestamp={new Date(lastUpdateTime).toISOString()}
              hasCopyIcon
            />
            {resourceTypes &&
              map(resourceTypes, resourceType => (
                <span style={{ marginLeft: '0.5em' }}>
                  <DetailMeta
                    text={resourceType.name}
                    linkTo={`/resourcetypes/${encodeURIComponent(
                      resourceType.id,
                    )}`}
                  >
                    <Icon name="category" />
                  </DetailMeta>
                </span>
              ))}
            <Tags tags={template.tags} onClick={noop} />
          </MetaContainer>
        </DetailContainer>

        <ButtonContainer>
          <Button as={Link} to={`/designer?template=${name}`} outline>
            Edit in designer
          </Button>
          <Button onClick={showModal}>
            <Icon name="assignment" /> Create task
          </Button>
          <Popup
            onCreate={instance => {
              popupRef.current = instance
            }}
            placement="bottom-end"
            appendTo={() => document.body}
            content={
              <List as={Segment} padding={0} interactive>
                <List.Item
                  as={Link}
                  to={`/tasks?query=${`template:"${name}"`}`}
                >
                  View {taskCount} tasks
                </List.Item>
                <List.Item onClick={() => reload.mutate()}>
                  Reload tasks
                </List.Item>
                <List.Item onClick={handleDownload}>Download</List.Item>
                {discoveryTemplate && (
                  <List.Item onClick={() => handleDemote()}>
                    Unlink discovery template
                  </List.Item>
                )}
                {!discoveryTemplate && (
                  <List.Item onClick={() => handlePromote()}>
                    Make discovery template
                  </List.Item>
                )}
                <List.Divider />
                <DangerListItem onClick={() => showRemoveModal()}>
                  <Icon name="delete_outline" /> Delete
                </DangerListItem>
              </List>
            }
          >
            <Button kind="secondary" outline>
              <StreamlineIcon
                iconData={Menu.NavigationMenuHorizontal1Alternate}
              />{' '}
              More actions
            </Button>
          </Popup>
        </ButtonContainer>
      </DetailsGrid>
    </>
  )
}

export default DetailView
